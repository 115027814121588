import { DotLocation } from '@/models/location.model';
import { DotUser } from '@/models/user.model';
import APIService from '../api';
import { Utils } from '../utils';
const apiService = new APIService();

const state = () => ({
    available: [],
    friends: [],
});
 
const getters = {
    available(state: any): Array<DotUser> {
        return state.available;
    },
    friends(state: any): Array<DotUser> {
        return state.friends;
    },
};
 
const actions = {
    async refreshFriends({commit}: any){
        const location = await Utils.getCurrentLocation();
        const statusLocation = new DotLocation();
        if (!location) {
            // No location found, use previous location.
        } else {
            statusLocation.lat = location.latitude;
            statusLocation.long = location.longitude;
        }
        const response = await apiService.post("friend/list", { lat: statusLocation.lat, long: statusLocation.long });
        if (response.status === 200 && response.data) {
            commit('updateFriends', response.data);
        }
        return response;
    },
    async addFriend(state: any, friendId: number){
        const response = await apiService.post("friend/add", { userId: friendId });
        return response;
    },
    async removeFriend(state: any, friendId: number){
        const response = await apiService.post("friend/remove", { userId: friendId });
        return response;
    },
    async blockUser(state: any, friendId: number){
        const response = await apiService.post("friend/block", { userId: friendId });
        return response;
    },
    async reportUser(state: any, friendId: number){
        const response = await apiService.post("report/user", { userId: friendId });
        return response;
    },
};
 
const mutations = {
    updateFriends(state: any, list: any){
        state.friends = list.friends
        state.available = list.available
    },
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}