import settingsModule from './modules/settings';
import friendsModule from './modules/friends';
import chatModule from './modules/chat';
import { createStore } from 'vuex';
 
const store = createStore({
    modules:{
        settings: settingsModule,
        friends: friendsModule,
        chat: chatModule
    }
});
 
export default store;