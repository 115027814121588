import moment from 'moment';
import { toastController, ToastButton, alertController } from '@ionic/vue';
import { closeOutline, checkmarkCircle, hourglassOutline, alertCircle, closeCircle } from 'ionicons/icons';
import { Coordinates, Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
const geolocation = new Geolocation()

export class Utils {
    static fromNow(dateString: string) {
        return moment(dateString).fromNow();
    }

    static async showToast(message: string, toastMode: ToastMode, buttonsOverride?: Array<ToastButton>, toastDuration = 2000) {
        let btns = new Array<ToastButton>();
        let cssClass = 'toast-polka';
        switch (toastMode) {
            case ToastMode.SUCCESS:
                cssClass += ' toastbtn-success';
                btns.push({
                    side: 'start',
                    icon: checkmarkCircle
                });
                break;
            case ToastMode.ERROR:
                cssClass += ' toastbtn-error';
                btns.push({
                    side: 'start',
                    icon: closeCircle
                });
                break;
            case ToastMode.ATTENTION:
                cssClass += ' toastbtn-attention';
                btns.push({
                    side: 'start',
                    icon: alertCircle
                });
                break;
            case ToastMode.INFO:
                cssClass += ' toastbtn-neutral';
                btns.push({
                    side: 'start',
                    icon: hourglassOutline
                });
                break;                
            default: 
                break;
        }
        btns.push({
            side: 'end',
            icon: closeOutline,
            role: 'cancel'
        });
        if (buttonsOverride) {
            btns = buttonsOverride;
        }
        const toast = await toastController.create({ message: message, color: 'dark', duration: toastDuration, cssClass: cssClass, buttons: btns});
        toast.present();
    }

    static async showAlert(header: string, message: string) {
        const alert = await alertController
        .create({
            header: header,
            subHeader: '',
            message: message,
            buttons: [
            {
                text: 'OK',
                id: 'cancel-button',
                cssClass: 'cancel-alert-button',
            },
            ],
        });
        await alert.present();
    }

    static async showConfirm(header: string, message: string, confirmCallback: Function) {
        const alert = await alertController
        .create({
            header: header,
            subHeader: '',
            message: message,
            buttons: [
                {
                    text: 'OK',
                    id: 'update-button',
                    cssClass: 'primary-alert-button',
                    handler: () => {
                        confirmCallback();
                    },
                },    
                {
                    text: 'Cancel',
                    id: 'cancel-button',
                    cssClass: 'cancel-alert-button',
                },
            ],
        });
        await alert.present();
    }

    static isSuccessfulResponse(resp: any, allowedSlug?: string) {
        let success = (resp && resp.status && resp.status > 199 && resp.status < 300);
        if (resp.data && resp.data.status && resp.data.status === 'error') {
            if (allowedSlug && resp.slug && resp.slug === allowedSlug) {
                success = true;
            } else {
                success = false;
            }
        }
        return success;
    }

    static async getCurrentLocation(): Promise<Coordinates> {
        return new Promise((resolve, reject) => {
            geolocation.getCurrentPosition().then((resp: any) => {
                resolve(resp.coords)
            }).catch((error) => {
                this.showToast('Location is required. Enable location access in your profile.', ToastMode.ATTENTION);
                console.log('Error getting location', error);
                reject();
            });
        });
    }
}
export enum ToastMode {
    SUCCESS = 1,
    ERROR = 2,
    ATTENTION = 3,
    INFO = 4
}