import { DotMessage } from '@/models/message.model';
import { DotUser } from '@/models/user.model';
import APIService from '../api';
const apiService = new APIService();

const state = () => ({
    chatUser: {},
    chatMessages: [],
    chatList: []
});
 
const getters = {
    chatUser(state: any): DotUser {
        return state.chatUser;
    },
    chatMessages(state: any): Array<DotMessage> {
        return state.chatMessages;
    },
    chatList(state: any): Array<DotUser> {
        return state.chatList;
    },
};
 
const actions = {
    async setChatUser({commit}: any, user: DotUser) {
        const response = await apiService.post("chat/messages", { userId: user.id });
        if (response.status === 200 && response.data && response.data.messages) {
            commit('setChatUser', user);
            commit('loadChatMessages', response.data.messages);
        }
        return response;
    },
    async loadChatList({commit}: any){
        const response = await apiService.get("chat/list");
        if (response.status === 200 && response.data && response.data.users) {
            commit('loadChatList', response.data.users);
        }
        return response;
    },
    async sendMessage({commit}: any, content: { userId: number, message: string}){
        const response = await apiService.post("chat/send", { userId: content.userId, message: content.message });
        if (response.status === 200 && response.data && response.data.messages) {
            commit('loadChatMessages', response.data.messages);
        }
        return response;
    },
};
 
const mutations = {
    setChatUser(state: any, user: DotUser){
        state.chatUser = user;
    },
    loadChatList(state: any, list: Array<DotUser>){
        state.chatList = list;
    },
    loadChatMessages(state: any, messages: DotMessage){
        state.chatMessages = messages;
    },
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}