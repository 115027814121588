import { Network } from '@capacitor/network';
import axios from "axios";
import { Preferences } from '@capacitor/preferences';

const production = true;
const apiUrl = (production ? 'https://polkadotservices.herokuapp.com/' : 'http://localhost:3000/');

export default class APIService {
    async postNoBearer(url: string, payload: any): Promise<any> {
        const status = await Network.getStatus();
        if (status.connected) {
            let resp = undefined;
            try {
                resp = await axios.post(apiUrl + url, payload);
                return resp;
            } catch (e: any) {
                return { status: 'error', message: e.message };
            }
        } else {
            return { data: { status: 'error', message: 'No network connection. Check your settings and try again.' } };
        }
    }
    async get(url: string): Promise<any> {
        const status = await Network.getStatus();
        if (status.connected) {
            try {
                const { value  } = await Preferences.get({ key: 'authToken' });
                const resp = await axios.get(apiUrl + url, {
                    headers: { Authorization: `Bearer ` + value }
                });
                return resp;
            } catch (e: any) {
                return { status: 'error', message: e.message };
            }
        } else {
            return { status: 'error', message: 'No data connection.' };
        }
    }
    async post(url: string, payload: any): Promise<any> {
        const status = await Network.getStatus();
        if (status.connected) {
            let resp = undefined;
            try {
                const { value  } = await Preferences.get({ key: 'authToken' });
                resp = await axios.post(apiUrl + url, payload, {
                    headers: { Authorization: `Bearer ` + value }
                });
                return resp;
            } catch (e: any) {
                return { status: 'error', message: e.message };
            }
        } else {
            return { status: 'error', message: 'No data connection.' };
        }
    }
    async postImage(presignedS3Url: string, base64Image: any, imageFormat: string) {
        const status = await Network.getStatus();
        if (status.connected) {
            const resp = await axios.put(presignedS3Url, base64Image, {
                headers: {
                    'Content-Type': 'image/' + imageFormat
                }
            });
            return resp;
        } else {
            return { status: 'error', data: {} };
        }
    }
    async delete(url: string): Promise<any> {
        const status = await Network.getStatus();
        if (status.connected) {
            try {
                const { value  } = await Preferences.get({ key: 'authToken' });
                const resp = await axios.delete(apiUrl + url, {
                    headers: { Authorization: `Bearer ` + value }
                });
                return resp;
            } catch (e: any) {
                return { status: 'error', message: e.message };
            }
        } else {
            return { status: 'error', message: 'No data connection.' };
        }
    }
}