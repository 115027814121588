
import { IonApp, IonRouterOutlet, IonLoading } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet, 
    IonLoading
  },
  computed:{
    ...mapGetters('settings', {
      loading: 'loading',
    })
  }
});
