import APIService from '../api';
const apiService = new APIService();
import { Preferences } from '@capacitor/preferences';
import { Utils } from '../utils';
import { DotLocation } from '@/models/location.model';
import { DotUser } from '@/models/user.model';

const state = () => ({
    dataConnected: true,
    user: {},
    authToken: null,
    apiVersion: '',
    loading: false
});
 
const getters = {
    user(state: any){
        return state.user;
    },
    dataConnected(state: any){
        return state.dataConnected;
    },
    authToken(state: any){
        return state.authToken;
    },
    loading(state: any){
        return state.loading;
    },
};
 
const actions = {
    async loginEmail(state: any, emailAddress: string){
        const response = await apiService.postNoBearer("auth/loginEmail", { emailAddress: emailAddress });
        if (response.status === 200 && response.data) {
            return { status: 'success', message: response.data };
        } else {
            return { status: 'error', message: 'There was an error while logging in, try again.' };
        }
    },
    async loginPassword({commit}: any, credentials: { emailAddress: string, password: string }){
        const response = await apiService.postNoBearer("auth/loginPassword", credentials);
        if (response.status === 200) {
            commit('saveAuthToken', response.data);
        }
        return response;
    },
    async register({commit}: any, credentials: { emailAddress: string, password: string, name: string }){
        const response = await apiService.postNoBearer("auth/register", credentials);
        if (response.status === 200) {
            commit('saveAuthToken', response.data.token);
        }
        return response;
    },
    async getMyProfile({commit}: any){
        const response = await apiService.get("profile/me");
        if (response.status === 200 && response.data && response.data.profile) {
            commit('saveUser', response.data.profile);
        }
        return response;
    },
    async updateProfile({commit}: any, profile: DotUser){
        const response = await apiService.post("profile/me", { name: profile.name, miles: profile.miles });
        if (response.status === 200 && response.data && response.data.profile) {
            commit('saveUser', response.data.profile);
        }
        return response;
    },
    async updateProfileSearchDistance({commit}: any, distance: number){
        const response = await apiService.post("profile/distance", { miles: distance });
        if (response.status === 200 && response.data && response.data.profile) {
            commit('saveUser', response.data.profile);
        }
        return response;
    },
    async updateStatus({commit}: any, status: string){
        const location = await Utils.getCurrentLocation();
        const statusLocation = new DotLocation();
        if (!location) {
            // No location found, use previous location.
        } else {
            statusLocation.lat = location.latitude;
            statusLocation.long = location.longitude;
        }
        const response = await apiService.post("profile/status", { status: status, lat: statusLocation.lat, long: statusLocation.long });
        if (response.status === 200 && response.data && response.data.profile) {
            commit('saveUser', response.data.profile);
        }
        return response;
    },
    async saveAuthToken({commit}: any, authToken: string){
        commit('saveAuthToken', authToken);
    },
    async clearAuthToken({commit}: any){
        commit('clearAuthToken');
    },
    async setLoading({commit}: any, loadingStatus: boolean) {
        commit('setLoading', loadingStatus);
    },
    async loadAppStorage({commit}: any){
        const { value  } = await Preferences.get({ key: 'authToken' });
        if (value) {
            commit('saveAuthToken', value);
        }
    },
    async logout({commit}: any){
        commit('clearAuthToken');
    },
    async updateProfilePicture(state: any, imageParams: { base64Image: Blob, format: string }) {
        const respImageParams = await apiService.get("image/url/" + imageParams.format);
        await apiService.postImage(respImageParams.data.presignedurl, imageParams.base64Image, imageParams.format);
        const resp = await apiService.post('profile/avatar', { avatarUrl: respImageParams.data.viewurl });
        return resp;
    }
};
 
const mutations = {
    saveUser(state: any, userProfile: any){
        state.user = userProfile;
        Preferences.set({
            key: 'user',
            value: userProfile,
        });
    },
    updateProfileSearchDistance(state: any, distance: number){
        state.user.miles = distance;
        Preferences.set({
            key: 'user',
            value: state.user,
        });
    },
    saveAuthToken(state: any, authToken: string){
        state.authToken = authToken;
        Preferences.set({
            key: 'authToken',
            value: authToken,
        });
    },
    clearAuthToken(state: any){
        state.authToken = null;
        state.user = null;
        Preferences.clear();
    },
    setLoading(state: any, loadingStatus: boolean){
        state.loading = loadingStatus;
    },
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}