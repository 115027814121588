export interface DotLocation {
    lat: number,
    long: number,
}
export class DotLocation implements DotLocation {
    constructor(lat?: number, long?: number) {
        if (lat) {
            this.lat = lat;
        }
        if (long) {
            this.long = long;
        }
    }
}