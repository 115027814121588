import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import ('../views/Login.vue')
  },
  {
    path: '/',
    component: () => import('../views/LoggedInLayout.vue'),
    children: [
      {
        path: 'chat',
        component: () => import('@/views/Chat.vue')
      },
      {
        path: '/tabs/',
        component: TabsPage,
        children: [
          {
            path: '',
            redirect: '/tabs/friends'
          },
          {
            path: 'chats',
            component: () => import('@/views/Chats.vue')
          },
          {
            path: 'friends',
            component: () => import('@/views/Friends.vue')
          },
          {
            path: 'available',
            component: () => import('@/views/AvailableUsers.vue')
          },
          {
            path: 'profile',
            component: () => import('@/views/Profile.vue')
          },
          {
            path: 'edit-profile',
            component: () => import('@/views/EditProfile.vue')
          }
        ]
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
